<template>
	<el-dialog v-model="dialogVisible" title="编辑" width="70%"  draggable @close="close">
		<el-form :model="form" :inline="true" label-width="120px">
		    <!-- <el-form-item label="商品图">
		      <el-upload
		          class="avatar-uploader"
		          :show-file-list="false"
				  :http-request="upload"
		          :on-success="handleAvatarSuccess">
		          <img v-if="form.goods_img" :src="form.goods_img"  class="avatar" />
		          <el-icon v-else class="avatar-uploader-icon" ><Plus class="icon"/></el-icon>
		        </el-upload>
		    </el-form-item> -->
			
			<el-form-item label="商品图" v-if="form.goods_img!=''">
			   <el-image style="width: 178px; height: 178px;border-radius: 10px;" @click="selectImg" :src="form.goods_img" fit="cover" />
			</el-form-item>
			<el-form-item label="商品图" v-if="form.goods_img==''">
			   <el-button type="" size="small" @click="selectImg">选择图片</el-button>
			</el-form-item>
			<el-form-item label="商品编号" >
			  <el-input v-model="form.goods_code" class="single"/>
			</el-form-item>
			<el-form-item label="商品名称" >
			  <el-input v-model="form.goods_name" class="single"/>
			</el-form-item>
			<el-form-item label="商品分类">
			  <el-select v-model="form.c_id" class="m-2 single"  clearable  placeholder="请选择商品分类" size="large">
			      <el-option 
			        v-for="item in classify"
			        :key="item.value"
			        :label="item.label"
			        :value="item.value"
			      />
			    </el-select>
			</el-form-item>
			<el-form-item label="价格">
			  <el-input v-model="form.price" />
			</el-form-item>
			<el-form-item label="库存">
			  <el-input v-model="form.stock" />
			</el-form-item>
			<el-form-item label="排序">
			  <el-input v-model="form.sort" />
			</el-form-item>
			<el-form-item label="是否易碎">
			   <el-radio-group v-model="form.is_ys" class="ml-4 single">
					<el-radio :label="item.value" size="large" v-for="(item,index) of all_code.is_ys">{{item.label}}</el-radio>
					<!-- <el-radio label="2" size="large">Option 2</el-radio> -->
			   </el-radio-group>
			</el-form-item>
			<el-form-item label="规格">
			  <el-radio-group v-model="form.is_norms" class="ml-4 " @change="normsClick">
				<el-radio :label="item.value"  size="large" v-for="(item,index) of all_code.is_norms">{{item.label}}</el-radio>
			  </el-radio-group> 
			  <span style="color:red;margin-left: 20px;">开启规格后，原商品库存将不在启用</span>
			</el-form-item>
			<norms v-if="norms_show" :norms_data="form.norms_data" :norms_value_data="form.norms_value_data" ref="norms_ref"></norms>
		</el-form>
		<template #footer>
			<div style="text-align: center;">
				<span class="dialog-footer">
					<el-button type="primary" size="small" @click="submit">保存</el-button>
					<el-button size="small" @click="close">关闭</el-button>
				</span>
			</div>
		</template>
	</el-dialog>
	<goods-img-dialog v-if="img_show"  @imgClose="imgClose"></goods-img-dialog>
</template>

<script setup>
import { reactive, ref ,defineEmits ,inject,defineProps,onMounted} from 'vue';
import {uploadUrl} from '@/utils/commom.js'
import {success,error} from '@/utils/tips.js'
import goodsImgDialog from './goodsImgDialog.vue'
import norms from './norms.vue'
import { Plus } from '@element-plus/icons-vue'
const dialogVisible = ref(true);
const $post=inject("$post");
const $get=inject("$get");
const $upload=inject("$upload");
const all_code=inject("$all_code");
const emit=defineEmits(['close'])
const props=defineProps(['uuid'])
const img_show=ref(false);
var norms_show=ref(false);
const norms_ref=ref();
var classify=ref({});
var form=ref({
	"uuid":props.uuid,
	"goods_code":"",
	"goods_img":"",
	"goods_name":"",
	"c_id":"",
	"c_name":"",
	"price":"",
	"stock":"",
	"is_ys":"",
	"is_norms":"",
	"update_time":"",
	"create_time":"",
	"norms_value_data":[],
	"norms_data":[]
})
onMounted(()=>{
	if(form.value.uuid!="") find();
	getClassify();
})
const selectImg=async()=>{
	img_show.value=!img_show.value;
}
const normsClick=async()=>{
	norms_show.value=form.value.is_norms=="1"?true:false;
	
}
const getClassify =async ()=>{
	const res=await $get('/Code/GetCode',{"code":"classify"})
	classify.value=res.result.classify;
}
const handleAvatarSuccess=async(res)=>{
	console.log(res)
}
const find=async ()=>{
	const res=await $get("/api/Goods/Find",{"uuid":form.value.uuid})
	form.value=res.result;
	norms_show.value=form.value.is_norms=="1"?true:false;
}
const submit= async()=>{
	if(norms_ref.value){
		form.value.norms_value_data=norms_ref.value.table_data
		form.value.norms_data=norms_ref.value.data
	}
	
	const res=await $post("/api/Goods/Add",form.value)
	if(res.errCode=="0"){
		error(res.msg);return;
	} 
	success(res.msg);
	close();
}
const upload= async (param)=>{
	const formData = new FormData()
	formData.append('file[]', param.file)
	const res=await $upload("/Upload/UploadImg",formData)
	if(res.errCode=="0"){
		error(res.msg);return;
	} 
	success(res.msg);
	form.value.goods_img=res.result[0];
}
const close=()=>{
	emit('close')
}
const imgClose=(val)=>{
	if(val){
		form.value.goods_img=val
	}
	selectImg();
}
</script>

<style scoped>
.el-button--text {
	margin-right: 15px;
}
.single{
	width: 600px;
}
/* .el-select {
	width: 300px;
}
.el-input {
	width: 400px;
} */
.dialog-footer button:first-child {
	margin-right: 10px;
}
.goods_select{
	height: 500px;width: 100%;
}
.goods_img {
	width: 90%;
	height: 150px;
}
</style>
<style >
	
	.avatar-uploader .el-upload {
	  border: 1px dashed #e3e8ef ;
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 178px;
	  height: 178px;line-height: 178px;
	  text-align: center;
	}
	.avatar{
		width: 178px;
		height: 178px;
		line-height: 178px;
	}
</style>
