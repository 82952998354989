<template>
	<div class="zdy_search" >
		<el-form :inline="true" :model="search_data" >
			<el-form-item label="分类名称">
				<el-select v-model="search_data.c_id"  class="m-2 single"  clearable  placeholder="请选择商品分类" size="mini">
				    <el-option 
				      v-for="item in classify"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value"
				    />
				  </el-select>
			</el-form-item>
			<el-form-item label="商品名称">
				<el-input v-model="search_data.goods_name" size="mini" placeholder="请输入商品名称" />
			</el-form-item>
			<el-form-item label="商品编码">
				<el-input v-model="search_data.goods_code" size="mini" placeholder="请输入商品编码" />
			</el-form-item>
			<el-form-item label="是否上架">
				<el-select v-model="search_data.is_delete"  class="m-2 single"  clearable  placeholder="请选择是否上架" size="mini">
				    <el-option 
				      v-for="item in is_delete"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value"
				    />
				  </el-select>
			</el-form-item>
			
			
			<el-form-item >
				<el-button type="primary" size="mini" @click="onSearch">搜索</el-button>
				<el-button type="" size="mini" @click="exportExcel">导出</el-button>
			</el-form-item>
		</el-form>
		<div class="hr"></div>
		<div class="use_btn">
			<el-button size="mini" type="primary" @click="add('')">新增</el-button>
		</div>
		
	</div>
	<div class="zdy_table">
		<!-- <div style="background: white;padding: 10px;width: 95%;margin-left: 2.5%;">数据</div> -->
		<el-table :data="tableData" id="exportTab" border  style="width: 95%;margin-left: 2.5%;" v-loading="loading"> 
		    <el-table-column prop="goods_img" label="商品图片" width="100">
				<template #default="scope" >
					<img  :src="scope.row.goods_img" class="goods_img"/>
				</template>
			</el-table-column>
			<el-table-column prop="goods_code" label="商品编号" />
			<el-table-column prop="goods_name" label="商品名称" />
			<el-table-column prop="c_name" label="商品分类" />
			<el-table-column prop="price" label="价格" />
			<el-table-column prop="stock" label="库存" />
			<el-table-column prop="norms_value_data" label="规格">
				 <template #default="scope" >
					<div v-for="(item,index) of scope.row.norms_value_data">
						<div>规格名：{{item.norms_value}}</div>
						<div>库存：{{item.stock}}</div>
						<div>价格：{{item.price}}</div><br/>
					</div>
				 </template>
			</el-table-column>
			<el-table-column prop="sort" label="排序" />
			<el-table-column prop="is_delete_str" label="是否上架" />
		    <el-table-column label="操作" fixed="right">
		      <template #default="scope" >
		        <el-button link type="primary"  size="mini" @click="add(scope.row.uuid)">
					<!-- <el-icon><EditPen /></el-icon> -->
					编辑
				</el-button>
				<br/>
				<el-popconfirm style="margin-top: 5px;" title="是否执行此操作？" @confirm="del(scope.row.uuid)" confirm-button-text="是" cancel-button-text="否">
					<template #reference>
						<el-button link type="danger" size="mini" >
							<!-- <el-icon><DeleteFilled /></el-icon> -->
							上架/下架
						</el-button>
					</template>
				</el-popconfirm>
		      </template>
		    </el-table-column>
		  </el-table>
	</div>
	<div class="zdy_page">
		<page-commom :search_data="search_data" @page_search='pageSearch'></page-commom>
	</div>
	<goods-dialog v-if="show" :uuid="uuid" @close="close"></goods-dialog>
</template>

<script setup>
import {ref,reactive,toRef,inject,onMounted} from 'vue';
import goodsDialog from './goodsDialog.vue'
import pageCommom from '@/components/commom/pageCommom'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
onMounted(()=>{
	onSearch()
	getClassify();
})
const show=ref(false);
const $get=inject('$get');
const $success=inject('$success');
const $error=inject('$error');
const loading=ref(false);
const classify=ref({});
const is_delete=ref({});
const getClassify =async ()=>{
	const res=await $get('/Code/GetCode',{"code":"classify,is_delete"})
	classify.value=res.result.classify;
	is_delete.value=res.result.is_delete;
}
let uuid=ref('');
const search_data=ref({
	'name':'',
	'page':1,
	'limit':10,
	'total':0,
	"goods_code":"",
	"request_type":"pc"
});
const exportExcel=async ()=>{
      // /* generate workbook object from table */
      // var xlsxParam = { raw: true } // 导出的内容只做解析，不进行格式转换
      // var wb = XLSX.utils.table_to_book(document.querySelector('#exportTab'), xlsxParam)

      // /* get binary string as output */
      // var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
      // try {
      //   FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '审核情况表.xlsx')
      // } catch (e) {
      //   if (typeof console !== 'undefined') {
      //     console.log(e, wbout)
      //   }
      // }
      // return wbout
	  
	  
	  loading.value=true;
	  let export_search_data=search_data.value;
	  export_search_data.limit="99999";
	  const res=await $get('/api/Goods/Export',export_search_data)
	  export_search_data.limit=10;
	  loading.value=false;
	  const data = XLSX.utils.json_to_sheet(res.result)//此处tableData.value为表格的数据
	  const wb = XLSX.utils.book_new()
	  XLSX.utils.book_append_sheet(wb, data, '商品表')//test-data为自定义的sheet表名
	  XLSX.writeFile(wb,'商品表.xlsx')//test.xlsx为自定义的文件名
	  
}


var tableData = ref([]);
const pageSearch=(data)=>{
	search_data.value=data.value
	onSearch();
}
const add=(val)=>{
	uuid.value=val
	show.value=!show.value;
}
const onSearch=async ()=>{
	loading.value=true;
	const res=await $get('/api/Goods/GetList',search_data.value)
	loading.value=false;
	search_data.value.total=res.result.total;
	tableData.value=res.result.data;
}
const del= async (val)=>{
	const res=await $get('/api/Goods/Delete',{"uuid":val})
	if(res.errCode=="0") $error(res.msg);
	$success(res.msg);
	onSearch();
}
const close=()=>{
	show.value=!show.value;
	onSearch();
}
</script>
	
	
<style scoped>
.goods_img{
	width: 70px;height: 70px;
}
.flex{
	display: flex;
}
.hr{
	border-bottom: 1px solid #edf0f6;
}
.use_btn{
	margin-top: 10px
}
.zdy_search {
	background: white;overflow: hidden;
	padding: 10px;border: 1px solid #edf0f6;
}
.zdy_table{
	margin-top: 10px;
}
.zdy_page{
	padding: 10px;width: 95%;margin-left: 2.5%;text-align: right;
}
</style>
