<template>
	<el-dialog v-model="dialogVisible" title="编辑" width="70%" :append-to-body='true' draggable @close="close">
	<div class="zdy_table">
		<span style="color: red;">注：双击选择图片</span>
		<el-row :gutter="10">
			<el-col :span="6" v-for="(item, index) of tableData">
				<div class="grid-content ep-bg-purple goods_div" >
					<el-image class="goods_img" fit="cover" @dblclick="selectImg(item.img)" :src="item.img" />
					<div class="goods_img_delete" @click="del(item.uuid)">
						<el-icon class="icon_text"><Delete /></el-icon>
					</div>
				</div>
			</el-col>
		</el-row>

	</div>
	<div class="zdy_page"><page-commom :search_data="search_data" @page_search="pageSearch"></page-commom></div>
	</el-dialog>
</template>

<script setup>
import { ref, reactive, toRef, inject, onMounted,defineEmits } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import {uploadUrl} from '@/utils/commom.js'
import pageCommom from '@/components/commom/pageCommom';
onMounted(() => {
	onSearch();
});
const show = ref(false);
const $get = inject('$get');
const $success = inject('$success');
const $error = inject('$error');
const loading = ref(false);
const $upload=inject("$upload");
const dialogVisible=ref(true);
const emit=defineEmits(['imgClose'])
let uuid = ref('');
const search_data = ref({
	name: '',
	page: 1,
	limit: 10,
	total: 0
});
var tableData = ref([]);
const pageSearch = data => {
	search_data.value = data.value;
	onSearch();
};
const add = val => {
	uuid.value = val;
	show.value = !show.value;
};
const onSearch = async () => {
	loading.value = true;
	const res = await $get('/api/GoodsImg/GetList', search_data.value);
	loading.value = false;
	search_data.value.total = res.result.total;
	tableData.value = res.result.data;
};
const del = async val => {
	ElMessageBox.confirm('您确定要删除吗?', '提示', {
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		type: 'warning'
	})
	.then(async () => {
		const res = await $get('/api/GoodsImg/Delete', { uuid: val });
		if (res.errCode == '0') $error(res.msg);
		$success(res.msg);
		onSearch();
	})
	
};
const upload= async (param)=>{
	const formData = new FormData()
	formData.append('file[]', param.file)
	const res=await $upload("/Upload/GoodsImgUpload",formData)
	if(res.errCode=="0"){
		$error(res.msg);return;
	} 
	$success(res.msg);
	onSearch();
}
const selectImg=(img)=>{
	emit('imgClose',img)
}
const close = () => {
	// console.log(456);
	emit('imgClose')
	// show.value = !show.value;
	// onSearch();
};
</script>

<style scoped>
.flex {
	display: flex;
}
.hr {
	border-bottom: 1px solid #edf0f6;
}
.use_btn {
	margin-top: 10px;
}
.zdy_search {
	background: white;
	overflow: hidden;
	padding: 10px;
	border: 1px solid #edf0f6;
}
.zdy_table {
	margin-top: 10px;
}
.zdy_page {
	padding: 10px;
	width: 95%;
	margin-left: 2.5%;
	text-align: right;
}
.goods_img {
	width: 90%;
	height: 150px;
}
.goods_div {
	text-align: center;
	margin-top: 20px;
	position: relative;
}
.goods_img_delete {
	height: 20%;
	bottom: 5px;
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	width: 90%;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
}
.icon_text_div {
	margin-left: auto;
	margin-right: 10px;
}
.icon_text {
	margin-left: auto;
	margin-right: 10px;
	font-size: 20px;
	color: white;
}
</style>
