<template>
	<el-divider content-position="left">规格</el-divider>
	<div class="title">
		<div class="add_btn"><el-button type="primary" size="mini" @click="addNorms" round>添加规格</el-button></div>
	</div>
	<el-row :gutter="12">
	    <el-col :span="24" v-for="(item,index) of data" style="margin-top: 10px;">
	      <el-card shadow="never"> 
			<div class="del"><el-button type="danger" size="mini" @click="delNorms(item.uuid)" round>删除规格</el-button></div>
			<el-form>
				<el-form-item label="规格名">
					<el-input v-model="item.norms_name" size="mini" placeholder="请输入规格名" />
				</el-form-item>
				<br/>
				<el-form-item label="规格值">
					<el-tag style="margin-left: 4px;" closable @close="delNormsValue(item.uuid,item1.uuid)"  v-for="(item1,index1) of item.children">{{item1.norms_value}}</el-tag>
					<el-input v-model="norms_value"  style="margin-left:10px;width: 100px;" size="small" placeholder="请输入规格名" />
					<el-button type="primary" size="mini" style="margin-left: 10px;" @click="addNormsValue(item.uuid)">
						+ 添加规格
					</el-button>
				</el-form-item>
			</el-form>
		  </el-card>
	    </el-col>
	</el-row>
	<el-row :gutter="12">
		<el-col>
			<el-table style="width: 100%" :data="table_data">
			    <el-table-column prop="norms_value" label="规格值" >
				</el-table-column>
				<el-table-column prop="price" label="单价" >
					<template #default="scope">
						<el-input v-model="scope.row.price"  style="margin-left:10px;width: 100px;" size="small" placeholder="请输入价格" />
					</template>
				</el-table-column>
				<el-table-column prop="stock" label="库存" >
					<template #default="scope">
						<el-input v-model="scope.row.stock"  style="margin-left:10px;width: 100px;" size="small" placeholder="请输入库存" />
					</template>
				</el-table-column>
			</el-table>
		</el-col>
	</el-row>
</template>

<script setup>
	import {ref,inject,toRaw,defineProps} from 'vue'
	import {randomString} from '@/utils/commom.js'
	const norms_value=ref('');
	const $error=inject('$error');
	const {norms_data,norms_value_data}=defineProps(["norms_data","norms_value_data"]);
	var data=ref(norms_data) || ref([]);
	var table_data=ref(norms_value_data) || ref([]);
	const addNorms=()=>{
		data.value.push(
			{"uuid":randomString(8),"norms_name":"","children":[]},
		)
	}
	const delNorms=(uuid)=>{
		data.value.forEach((item,index)=>{
			if(item.uuid==uuid){
				data.value.splice(index,1);
			}
		})
		tableDataUpdate();
	}
	const addNormsValue=(uuid)=>{
		if(norms_value.value==""){
			$error('请输入规格值');return;
		} 
		
		data.value.forEach((item,index)=>{
			if(item.uuid==uuid){
				var judge="1";
				item.children.forEach((item1,index1)=>{
					if(item1.norms_value==norms_value.value) judge="2";
					
				})
				if(judge=="1"){
					data.value[index].children.push(
						{"uuid":randomString(8),"norms_value":norms_value.value}
					)
				}
			}
		})
		norms_value.value="";
		tableDataUpdate();
	}
	const delNormsValue=(parent_uuid,uuid)=>{
		data.value.forEach((item,index)=>{
			if(item.uuid==parent_uuid){
				data.value[index].children.forEach((item1,index1)=>{
					if(item1.uuid==uuid){
						data.value[index].children.splice(index1,1);
					}
				})
			}
		})
		tableDataUpdate();
	}
	const tableDataUpdate=()=>{
		// let finalArr = cartesianProductOf(...attrValue)
		var childrens=[];
		data.value.forEach((item,index)=>{
			childrens.push(data.value[index].children);
		})
		
		let finalArr = cartesianProductOf(...childrens)
		if(finalArr.length==0){
			return;
		}
		var result_arr=finalArr;
		var result_data=[];
		result_arr.forEach((item,index)=>{
			let str="";
			item.forEach((item1,index1)=>{
				if(str!="")
					str=str+"|"+item1.norms_value;
				else
					str=item1.norms_value;
			})
			let judge="1";
			table_data.value.forEach((td,i)=>{
				if(td['norms_value']==str){
					judge="2";
					result_data.push({
						"uuid":td['uuid'],
						"norms_value":td['norms_value'],
						"stock":td['stock'],
						"price":td['price']
					});
					return;
				}
			})
			if(judge=="1"){
				result_data.push({
					"uuid":randomString(8),
					"norms_value":str,
					"stock":0,
					"price":0
				})
			}
		})
		table_data.value=result_data
	}
	// 笛卡尔积算法
	const cartesianProductOf=(...args)=> {
		return args.reduce(
			(total, current) => {
				let ret = []
				total.forEach((a) => {
					current.forEach((b) => {
						ret.push(a.concat([b]))
					})
				})
				return ret
			},
			[
				[]
			]
		)
	}	
	defineExpose({
		table_data,
		data
	})
</script>

<style scoped lang="scss">
	.title{
		padding: 10px;display: flex;
		.add_btn{
			text-align: right;width: 100%;
		}
	}
	.del{
		text-align: right;
	}
</style>